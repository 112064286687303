dmx.Component('data-view', {

    initialData: function() {
        return {
            data: [],
            page: 1,
            pages: 1,
            items: 0,
            sort: {
                on: '',
                dir: 'asc'
            },
            has: {
                first: false,
                prev: false,
                next: false,
                last: false
            }
        };
    },

    attributes: {
        data: {
            type: [Array, Object],
            default: []
        },

        filter: {
            type: String,
            default: ''
        },

        page: {
            type: Number,
            default: 1
        },

        pageSize: {
            type: Number,
            default: 0
        },

        sortOn: {
            type: String,
            default: ''
        },

        sortDir: {
            type: String,
            default: 'asc',
            validate: function(val) {
                return /^(asc|desc)$/i.test(val);
            }
        }
    },

    methods: {
        select: function(page) {
            this.setPage(Number(page));
        },

        first: function() {
            this.page = 1;
            this.setPage(1);
        },

        prev: function() {
            var page = this.page - 1;
            if (page < 1) page = 1;
            if (page > this.data.pages) page = this.data.pages;
            this.page = page;
            this.setPage(page);
        },

        next: function() {
            var page = this.page + 1;
            if (page < 1) page = 1;
            if (page > this.data.pages) page = this.data.pages;
            this.page = page;
            this.setPage(page);
        },

        last: function() {
            var page = this.data.pages;
            this.page = page;
            this.setPage(page);
        },

        sort: function(prop, dir) {
            this.props.sortOn = prop;
            this.props.sortDir = (dir && dir.toLowerCase() == 'desc' ? 'desc' : 'asc');
            this._update();
        }
    },

    render: function(node) {
        this.update({});
    },

    update: function(props) {
        if (JSON.stringify(this.props) != JSON.stringify(props)) {
            if (JSON.stringify(this.props.data) != JSON.stringify(props.data)) {
                this.setData(this.props.data);
            }
            if (this.props.page != props.page) {
                this.page = this.props.page;
            }
        }

        this._update();
    },

    _update: function() {
        var filter = this.props.filter;
        var sortOn = this.props.sortOn;
        var sortDir = this.props.sortDir.toLowerCase();
        var pageSize = this.props.pageSize;

        this.filtered = this.items.slice(0);

        if (filter) {
            this.filtered = this.filtered.filter(function(item) {
                return dmx.parse(this.props.filter, dmx.DataScope(item));
            }, this);
        }

        if (sortOn) {
            this.filtered.sort(function(a, b) {
                return a[sortOn] < b[sortOn] ? -1 : a[sortOn] > b[sortOn] ? 1 : 0;
            });
        }

        if (sortDir == 'desc') {
            this.filtered.reverse();
        }

        if (this.filtered.length) {
            this.set('pages', pageSize ? Math.ceil(this.filtered.length / pageSize) : 1);
        } else {
            this.set('pages', 1);
        }

        this.set('items', this.filtered.length);
        this.set('sort', {
            on: sortOn,
            dir: sortDir
        });

        this.setPage(this.page);
    },

    setData: function(data) {
        this.items = [];

        if (data && typeof data == 'object') {
            if (Array.isArray(data)) {
                this.items = data.map(function(item) {
                    return (typeof item === 'object') ? item : { $value: item };
                });
            } else {
                for (var key in data) {
                    this.items.push({ $key: key, $value: data[key] });
                }
            }
        }
    },

    setPage: function(page) {
        var pageSize = +this.props.pageSize;
        var data = this.filtered.slice(0);
        if (page < 1) page = 1;
        if (page > this.data.pages) page = this.data.pages;
        //this.page = page;
        this.set('page', page);
        this.set('data', pageSize ? data.splice((page - 1) * pageSize, pageSize) : data);
        this.set('has', {
            first: page > 1,
            prev: page > 1,
            next: page < this.data.pages,
            last: page < this.data.pages
        });
    }

});

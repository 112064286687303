dmx.Component('data-iterator', {

    initialData: function() {
        return {
            index: -1,
            value: null,
            has: {
                first: false,
                prev: false,
                next: false,
                last: false
            }
        };
    },

    tag: 'div',

    attributes: {
        data: {
            type: Array,
            default: []
        },

        index: {
            type: Number,
            default: 0
        },

        loop: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        first: function() {
            this.first();
        },

        prev: function() {
            this.prev();
        },

        next: function() {
            this.next();
        },

        last: function() {
            this.last();
        },

        random: function() {
            this.random();
        },

        select: function(index) {
            this.select(index);
        }
    },

    render: function(node) {
        this.items = [];
        dmx.BaseComponent.prototype.render.call(this, node);
        this.setData(this.props.data);
        this.select(this.props.index);
    },

    update: function(props) {
        if (JSON.stringify(props.data) !== JSON.stringify(this.props.data)) {
            this.setData(this.props.data);
            this.select(this.props.index);
        }

        if (props.index !== this.props.index) {
            this.select(this.props.index);
        }
    },

    setData: function(data) {
        if (data) {
            if (Array.isArray(data)) {
                this.items = data;
            } else {
                console.warn('Iterator data is not array but a ' + (typeof data));
            }
        }
    },

    first: function() {
        if (this.items.length) {
            this.select(0);
        }
    },

    prev: function() {
        if (this.items.length) {
            index = this.data.index - 1;
            if (index < 0 && this.props.loop) {
                index = this.items.length - 1;
            }
            this.select(index);
        }
    },

    next: function() {
        if (this.items.length) {
            index = this.data.index + 1;
            if (index >= this.items.length && this.props.loop) {
                index = 0;
            }
            this.select(index);
        }
    },

    last: function() {
        if (this.items.length) {
            this.select(this.items.length - 1);
        }
    },

    random: function() {
        if (this.items.length) {
            this.select(Math.floor(this.items.length * Math.random()));
        }
    },

    select: function(index) {
        index = parseInt(index, 10);

        if (this.items.length) {
            var last = this.items.length - 1;
            if (index < 0) index = 0;
            if (index >= this.items.length) index = last;
            this.set('index', index);
            this.set('value', this.items[index]);
            this.set('has', {
                first: index > 0,
                prev: index > 0,
                next: index < last,
                last: index < last
            });
        } else {
            this.set('index', -1);
            this.set('value', null);
            this.set('has', {
                first: false,
                prev: false,
                next: false,
                last: false
            });
        }
    }

});
